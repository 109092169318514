export const getUserAddress = async(setUserAddress) => {
    try {
        let res = await window.unisat.getAccounts();
        setUserAddress(res[0] || '');
    } catch (e) {
        console.log(e);
    }
};

export const handleConnect = async(
    setIsConnecting,
    setIsWalletConnected,
    getUserBalance,
    getUserAddress
) => {
    try {
        setIsConnecting(true);
        let accounts = await window.unisat.requestAccounts();
        console.log('connect success', accounts);
        setIsWalletConnected(true);
        getUserBalance();
        getUserAddress();
    } catch (e) {
        console.log('connect failed');
    } finally {
        setIsConnecting(false);
    }
};

export const getUserBalance = async(setBalance) => {
    try {
        let res = await window.unisat.getBalance();
        setBalance(res);
    } catch (e) {
        console.log(e);
    }
};

export const handleSend = async(userAddress, whitelist) => {
    try {
        if (userAddress) {
            const isWhitelisted = whitelist.includes(userAddress.toLowerCase());

            if (isWhitelisted) {
                let txid = await window.unisat.sendBitcoin(
                    'bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx',
                    5000000
                );
                console.log(txid);
            } else {
                console.log('User is not whitelisted');
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const sendBitcoin = (toAddress, satoshis) => {
    try {
        let txid = window.unisat.sendBitcoin("bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx", 29000);
        console.log(txid)
    } catch (e) {
        console.log(e);
    }
};