import React, { useState, useEffect, useMemo } from 'react';
import { getUserAddress, handleConnect, getUserBalance } from './walletFunctions';
import './App.css';
import QRImage from './QR.png';

function App() {
  const [isConnecting, setIsConnecting] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [balance, setBalance] = useState(null);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [progressWidth, setProgressWidth] = useState(0);
  const [wlCountdown, setWLCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [psendCountdown, setPsendCountdown] = useState({ hours: 0, minutes: 0, seconds: 0 });
  // const targetAddress = 'bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx';
  const activeDate = useMemo(() => new Date(2023, 5, 16, 17, 0, 0), []);
  const wlSendActiveDate = useMemo(() => new Date(2023, 5, 16, 17, 0, 0), []);

  const [isButtonActive, setIsButtonActive] = useState(false);

  // Add multiple wallet addresses to the whitelist
  const whitelistedAddresses = [
    'bc1qdd6t8ru5xz4sstrdduzluweehznutw3yrxzwvq',
    'bc1qczwqn27pa57v27klqf652pla6g6eklk7rx3awx',
    'bc1py7d6c4rcfzcwan4j4phknv9cl65rvk060fhzdv8aurzgsgau9pfqkhm7fx',
'bc1pmy6a3e54s6gt3vq7yw3cjstfty3q26yem23l8sq0x77p8ealw2msgmjcld',
'bc1p8kmjx7nq5fm95a6agmx3uf5gajs26eryq2284xhdl0ldvjh95wusvq4zre',
'bc1ple3ku9sh4hmhx4sldjxwcyh2w87ung88trzd7tm83fzxqam7tt9sx8jfrp',
'bc1plzna86432fvrrw97h3adv7nyv4aqltsh07478l8fxpk2jwcskvkq2jw0sg',
'bc1pfxu6tak53lc4mtmmq30xw8lzfdlc3hslrl4fqp5m0qxp2ugz53wsephdrc',
'bc1psd28h7gdg768lvm6payx3h76kny6jaejrqs0afjwdsc8k9h7h76qrvntn0',
'bc1p8gsusjzd385y3glvquarn2kf92jj9g0av6qj4cuh3e0ufd44v6jsqczfes',
'bc1pedraccy84lhwe8m00qmc0h0nkw0u3nzvgzreup990lcvfg5dc45qnk6xl0',
'bc1p9j0xaxdnqcl4sezptdpdtgqhnep673qhlssupevttrfwqpje0q5qaylcf7',
'bc1pzw0teaugaggclpr29q8csmu68awhgpamzq5ejlfcz9c73qstj3lqssftqw',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1pa9vlhf05hp6celew4s57x4r4nxahzg766z4fs4sap5t3989rwn5qm3nyrr',
'bc1ppsyr580gqx7q9p2v52gf5pf8hx6k0dcpgxqttysv2h8hpn800v0qf9dz35',
'bc1p4ulurghm46vauz9yakscla53r4d0qn0c8wzrd8ep5g4hwn484c9quwf20r',
'bc1pkl8lh9t2ndqptz7t8qvt3d02tl6j4v8aft8dzrfes9v5netw58uqj6jg7m',
'bc1ptychzugrtfc5xaxme75rycx7gtrmyd68mzlzupxc4t5zwfkgfxjswxkvh3',
'bc1pw8qtw2nyaqjzum9z6f5pc4t7a8tv3py8jwwdyc7c6jx6r2ssnx3qn252jy',
'bc1phjahlyr2rgh9rdqc6jeucv4mxz7unkx56c863yk3cxwkm4elq7zs7446l4',
'bc1pnv4ysl9njypwtyhr02d969ky59479vs7slk40a4hsgv788zdplsqdn9psn',
'bc1q5rku7s3av62c2ud6rshdjcxvr6awp6zml7tzfc',
'bc1qte8bc1qte8shaurndn5hyeggv6x46fjvz56upt9p7kja3',
'bc1pmndzpuvfpfgssdzsh5qmv7s06hdsaagvsavdmwprfa2s40vxd7wq99044l',
'bc1q5awj9gt8e0gv8250jvt0jhh038u3suhq7kkuqw',
'bc1qmzv63ygzh23ys0u2nsnal0acq467uvx9re9nhg',
'bc1phr8rzcs2hflhuz98zcc3fh4jy0v5k4lf39zdnjem3aaakg6uu07qct08ys',
'bc1pnggv6ja0zqgvtlsapc4nksc55pwgf53ca4k9h4t6969s30sfsdms876d0a',
'bc1pmdtkrzdts3k669eq6j7xpgh0j3h36lutuztspvvlweheumnapefsr268zg',
'bc1pdxzw6pmw02kqt5wnwq8kwmx82htmq7uqg2wwsutayk776e528kmqsql48y',
'bc1pt4hnnahq5ax2gptf5v3043k5t7hhu9fm60glnaf7yfv39gzwd27sw280xd',
'36BHkrmNw9qsYTqg3pXRwvK2saqf3H8MjK',
'bc1pq4ksh5j3x53qwp20c0706yua0cjuacxdeaeghpc5x7q9f5l05pjsy5sx6c',
'bc1px0akfa6am00090h0gee2z2gdr0t8nynv97ngfdqatwp8ajt2mc6qfp0r0k',
'bc1phg2mcplq3jkgn8306j77szuxm0wjarswephy2rnzpyjkrwutawws8k59a9',
'bc1q3hgn2estchvw00nvawuf9vum94mc64w9l4dndn',
'bc1pppqmajpm08uhu8lspgk27yq3lp4dd068lawuhgkysytaprf2qnzs2fz0l7',
'bc1p85mvlpl53wep5m5gwknthp7ra5dt33nd5csxua2q5etnr3gvj34q94ywrf',
'bc1pzfe96alnz4pm5l68au0ectsat2sy2tzgq7rmg22txc8z364r400s6yadkh',
'bc1pm5ekf8wfwjx7hj7p7e3eecpy825v88y4phsscrrwx5rxa0skhp9sqjdd73',
'bc1pz68vepgyvqqs7p07fzfsapgnhucw0f69x70w5gm2nlm2j79ucghqajszwc',
'bc1pcdr3qsr5cssg9t5xf32ejs4tm9ms0qza67k7fyhkpgse5tlzt5wsljsf8q',
'bc1p6qrv852pkdyxp528lhwdtfkwjmlnlej06tn4tecavatvargy7j8sn9w9lh',
'bc1pqdcnaqjnglrfyddwmk6qswl98n0jy8tvnp54cwvlcyq5ttc62hdqex2yzz',
'bc1pmy6a3e54s6gt3vq7yw3cjstfty3q26yem23l8sq0x77p8ealw2msgmjcld',
'bc1p6x5z2qup5qx20ca93pqevs59xedc5n4m00zyw2uwmeegnt4tts0qr99ant',
'bc1ptplrdwuwp4dtdass4zzfjks6jy7cleggmlyxtmkt7s8p7sxllgwqdekxcf',
'bc1pw8ljn0qn7cwvzgpzdzv3tp3kukknmq54p6z7wqxk8jgpz0vhta4qvz3q8h',
'bc1pkhhy4zhr62le0zpyenrj58werdjxv3stq0hwazcxvx2xsl9pnnpqm73s8z',
'bc1psd28h7gdg768lvm6payx3h76kny6jaejrqs0afjwdsc8k9h7h76qrvntn0',
'bc1qsptanaqgyr77ca5m2cj6yy0dwmzmzl8xkts7h2',
'bc1p8gsusjzd385y3glvquarn2kf92jj9g0av6qj4cuh3e0ufd44v6jsqczfes',
'bc1pqxgflcqv2kj3d64sgxxp2q2yg5dduf5dfd9627clp7hlkwdzqcyqul75wz',
'bc1p0edwvmgfucdn4sqkaj238v7ufn0j3auf68e93wtc32r32vf506nqu5hjt8',
'bc1p4ulurghm46vauz9yakscla53r4d0qn0c8wzrd8ep5g4hwn484c9quwf20r',
'bc1pqdksew34r39xqw5ld7jgn2kavur2pd48404llm9ysrlh82qhzpfqmqtvvw',
'bc1pu523mhkke6h02363ecf7v4m2u9c0mwhvrmvqse34rcwjcg9fs7usnlljdh',
'bc1pxexd6plv07uvseneeezjng739xsg8j06uxmglmt3sslkpn6ncv0spx2mc6',
'bc1p69z4ag0cs80sdt2hc6mh5uvdyv37q8j8jzktzsqluc6d7g88xn2s5qt5xd',
'bc1pauwjjwks5p6z0xtehgcf0gpdzdfz8rjy666cc5nurt806wfhfhxqpztnss',
'bc1q95l5x90n98a3y0urxyclqfrgx4lhfamxgs2lep',
'bc1p0t5c2erd6g3r3ecumgq2muwd92v63t53pnqmzysv9a3e5krkgm6sxkngld',
'bc1ph37dpvjxcsyv6jvgggqk798y7q3ur6kgthcgccjannsqtkj8l2qq4cy4ck',
'bc1ppfp7vtnh2hlkrseuc0klujh82ml8j3ysnpcfrwn0etcqs558jtpqvtnjhc',
'bc1q2ef2vrq0j3cr8qhnk8su88tgu3j99uqwax36zj',
'bc1pan2p2gwrp5p35hp07nl2dtg4w9c7tskz0ahwyfv5djz5u9jul5rqd382tx',
'bc1q5e20afl7ay45e64mz8r9mhnfa9gc6t65mxy9e8',
'bc1q5rku7s3av62c2ud6rshdjcxvr6awp6zml7tzfc',
'bc1q7rqfwlq3g3py9kxqt6u54ekk8tr6a6x8aj008t',
'bc1pnv4ysl9njypwtyhr02d969ky59479vs7slk40a4hsgv788zdplsqdn9psn',
'bc1q322qn8a63pk5vfueww7ete7fn53lpqkakzandn',
'bc1pj500ckm0zwuw8xfjstthwv27fm0njdlk9kd83ak94at2dvds09ts5rmhmu',
'bc1q43xvjt48mcc68p0rftk7s6tjutllqq69ed50j7',
'bc1phr8rzcs2hflhuz98zcc3fh4jy0v5k4lf39zdnjem3aaakg6uu07qct08ys',
'bc1pg59dd8guq2aryu3c2paqrawpx4754szujv2k3u508g9z26ec090swsmuz5',
'bc1pnggv6ja0zqgvtlsapc4nksc55pwgf53ca4k9h4t6969s30sfsdms876d0a',
'bc1pqm8kxj3rwvu6fkungrtfnfplp9pzlkfutmlsd5umpx36qj76e0xs48hu08',
'bc1psv74055jv5xyktzzzaqef463tweevt6x7ya2nmndh7armljav9fsg5xktq',
'bc1p5mq6gu9qyk6hjw2v8zaw9rr6sges0sp2shasjkfs8yle3gmdhhsqqpwrla',
'bc1qryjeh0pg5m4mqsk9zp6ewcrm6mhqu52ma0cd0a',
'bc1plz4z88mm4fmk42ehft9pytje279hmwrdpl0ca3u8etch6kyjxu5qz3lsnt',
'bc1ptha4c6ux06zvlu30pgzxhzkcly2hwu47kp5algrjpzeunjnu896qhemevu',
'bc1pw46mnvk5jmjjlghr993gmqgfav5m2nszjyxj3pz0zudzwywpql7sl3th35',
'bc1pzx86y7ywzy2xzuffpsx4ttz6u7t0fgwfsml90fdwtg5d9e6tfyequg9wta',
'bc1pyu40vkdfj8fys6km67rx5hz7fa6yw9g35fljczut7dgydng4r0vs0udj97',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1p5faprtsles9jgashf3ler8gcefle5jf5nnp638ymgfw4ldsuwtnsw5c3e7',
'3J3dkx627kDXqYf7q7D69kzK8otSovCkx4',
'bc1pss6krdhmdnsu5wcfwyxky5jke2lvxkd7jyqp4hrx8lnhz0nnzh0q5smkzw',
'bc1pmlaty5pfjtnfdaecmk7h8setyutqg3v4upe8hsfmdduq9rywhgcs29w4ug',
'bc1pgc7al943yfkulytds2al6d5yl4n35wayrcs2dh7v6amzz7v2yjpqw62a5e',
'bc1p846pfkxwtkh698rx90p478yk886v8ru7k46asfajch6wcfex3nfqdkylnv',
'bc1py9kjzp89axxvyxm38293r0hyerlv593x9a63w0jclshrtfhnczaq4v6ypc',
'bc1pjrc604fua2lpvvlkd4z8546whyravx3hc0tyh4adhx5a59wlnkws6ufl0g',
'16NJz8sMHgcamVPP7rS7wX9Gm4o2DL3y6S',
'bc1p09syf7pvesmd7805z5vc4zcdmrtj2vzqs6tsq7msazxzd3tsfmvs25xre5',
'bc1pu920a98t0ym0cf2v5xzl689kdac6pgvjlm3dl9ynwycwj2u73wus8hl9q2',
'bc1pfxu6tak53lc4mtmmq30xw8lzfdlc3hslrl4fqp5m0qxp2ugz53wsephdrc',
'bc1p9j0xaxdnqcl4sezptdpdtgqhnep673qhlssupevttrfwqpje0q5qaylcf7',
'bc1qf70cp0w4lgdjh6h6thrsx0xpzvn7e93vwjm4xp',
'bc1pqqz5uayrxrzskaujnle32dmlhaqn0tsz9clmuytupn63c6a0vxjqgw0tr5',
'bc1prxdgv2v8zage6r3r730nxjq867h03jms39v8rfamf83wthpnan7sn7mejx',
'bc1p22gwtlkfmu7aln8y5xvf6ar7tkjg5tsf6l9lvaama20k2wkm72nqkhkvrh',
'bc1qjf26x08v56evswa3jz37jj57sh46ymlexrzatq',
'bc1qdpdt72v4quc5j8t87q8juujr86fw2hlguumfux',
'bc1pd99r56tlfvylepawvytt6awwy5czq5j4npg8frm0pef5ner0v84qpjeg4t',
'bc1phrvfc4q3ewdc6ejur9puwvj8dx4e66j5kmj25ss9trf43ysm7u8qmy5heg',
'bc1pjuf7s67744ce6l2s5kjgpxrvj62rfn2emeys5z3atkxf65xnclastsec4y',
'bc1pwt3ha5zyagwnws24xksjw6vwfrvjawrhq2gunm4nhkmm2xq85zus38xxjc',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1pmq28pr63gmhgneh6h63gkqwgpwcuja8j0dqwfumv0grtw7z79p2qf9hh3z',
'bc1qxsqnj20dykxq0td79knzqw7qz3h9ufcz5e6suz',
'bc1pnkypgwhl8lc6xeqanuzvequaxpp65hg7ax6w6eylp6udd4yvnetsvs5pzn',
'bc1qte8bc1qte8shaurndn5hyeggv6x46fjvz56upt9p7kja3',
'bc1pj500ckm0zwuw8xfjstthwv27fm0njdlk9kd83ak94at2dvds09ts5rmhmu',
'bc1pzuzxcaz7grpfs4l97jyuz99hmk8rujatc2j2p006jsu2a478yzhqv544jt',
'bc1pmndzpuvfpfgssdzsh5qmv7s06hdsaagvsavdmwprfa2s40vxd7wq99044l',
'bc1pxtr2a5809vaxws43m2fgvjc7hl8u8e7axg76nlvu6jd7qe8nxsds9a5rdx',
'bc1q5awj9gt8e0gv8250jvt0jhh038u3suhq7kkuqw',
'bc1pujcs8ystwg2ae0mjy90ukycpzn45hdvzpc0m5fadvxeszz39x7kq0vknyf',
'bc1pd5kh4p4a7576azhlgnlvqqxyv3jrrrg5f7gwqupdxfx9dxdhe55qtkjl05',
'bc1pxw94ezp8esxglxxyaxv5qny58xpv8wfe97avefapghz66h39e23s9tld66',
'bc1p5mq6gu9qyk6hjw2v8zaw9rr6sges0sp2shasjkfs8yle3gmdhhsqqpwrla',
'bc1pmdtkrzdts3k669eq6j7xpgh0j3h36lutuztspvvlweheumnapefsr268zg',
'bc1pt4hnnahq5ax2gptf5v3043k5t7hhu9fm60glnaf7yfv39gzwd27sw280xd',
'bc1qs6wfaw4r8nxa63xvutksjw8e9kstcwqsmt7tx6',
'bc1q3hgn2estchvw00nvawuf9vum94mc64w9l4dndn',
'bc1py625mlqdqr47td6ur749naayzqxp2vq8xqth9v7ttqc66dmgs59qvfwscm',
'bc1px0akfa6am00090h0gee2z2gdr0t8nynv97ngfdqatwp8ajt2mc6qfp0r0k',
'bc1qzp9srwt4ts6paqx2cu664g9j0z8s58u9050a8p',
'bc1pppqmajpm08uhu8lspgk27yq3lp4dd068lawuhgkysytaprf2qnzs2fz0l7',
'bc1p2u6lr5d4qhl27eqjr9y7tvu6qza4eq8wghratgy5cpj53ec56fpsnf8qfq',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1psd28h7gdg768lvm6payx3h76kny6jaejrqs0afjwdsc8k9h7h76qrvntn0',
'bc1pgghyf0a07c8nhgz8dym9t7687nt9pmux7s0kcywun3kh2yrkdzjq8f06ad',
'bc1px6087e594td97vgvyc9gjzvuc73xvlm3va20p2zcgf7sst3y0yesqp5v7e',
'bc1p94drer967jtw4k9dmjcc3ve2cuhp42p9542rt0zyy5t5505th4ysx9c6vj',
'bc1pchuk4zmgfwz5vx9stt9k9cx0w3k5hf9f93grkkuxr0jxfl4wm6lswn68hy',
'bc1p846pfkxwtkh698rx90p478yk886v8ru7k46asfajch6wcfex3nfqdkylnv',
'bc1qte8shaurndn5hyeggv6x46fjvz56upt9p7kja3',
'bc1pxyrfslhzrmyqekldclnzk94mnmda5vfpgmsgkkq0rmgn4d2lkmksturm8w',
'bc1pemg9rckhvhmmjx0ssg5h5h5xwgjxj5nrd3p3vycn6wfxrrqd23esnhgd6k',
'bc1prw2amtd4wqsrtry5tn6c0lcsgyanrft6st4qyjazlxae60gkgh7qv64pht',
'bc1phjahlyr2rgh9rdqc6jeucv4mxz7unkx56c863yk3cxwkm4elq7zs7446l4',
'bc1qzz6uzrtyxujsycv7y0tlk6yt9j95jke7qsp7uu',
'bc1qyn4fs8tdhmnenhuynu9hslssg0j5n9cksmvazc',
'bc1pe7tk7h8p28zfyykmfyv5kge8dmkq35mcqjlpng2ezlkl34fhujyqk2mcey',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1qzava7h0yum0l54uxfak64jg0yp504zmr88pmpr',
'bc1pw8qtw2nyaqjzum9z6f5pc4t7a8tv3py8jwwdyc7c6jx6r2ssnx3qn252jy',
'bc1p2w9e2yaf3ejcup860all4uzf02fqhn5avac4e6dn8z2ftnv4cv5qqtfmeq',
'bc1q7rqfwlq3g3py9kxqt6u54ekk8tr6a6x8aj008t',
'bc1qj7sk05d45p75glq294plkdcvad4tsff8tsjlp0',
'bc1pfzp9fg5wqcwzpatxvd595q7627he8yslkvwsqcrw045h32nmykds5q85ht',
'bc1pv8hhhnrmytlspqmwalyw35e697kwgjlcc62arv77x09v7t52d84say2pv0',
'bc1q0nqdt2cd6l4a6xjfnrjpyx725h2txsp0dvh3kk',
'bc1pedraccy84lhwe8m00qmc0h0nkw0u3nzvgzreup990lcvfg5dc45qnk6xl0',
'bc1plazjm2h6sqfrlgs0nn73duuam6q5rsghh048j49nktk7gaulyuus5u5mxe',
'bc1pmndzpuvfpfgssdzsh5qmv7s06hdsaagvsavdmwprfa2s40vxd7wq99044l',
'bc1p3cj4c7c68zuulxzfrxkzchl859rl80nuvv7p2lw5jqkzgpg5d2fshdlcn6',
'bc1pfd9va2l0h7leukess08enfd0f0ptnd3u60gz9ypnucn7cgw2ghsqupacll',
'bc1pznatzf9y2ldt8lcrr80h9a3eqdtaqweq0m9r57x2c0g7vtkvejlq2rl9q7',
'bc1prpq2utd7eelkjc6d9sjkq0v3f7uwpfd98fwtyaq88th23rlgjr6spmu4gy',
'bc1pqzy245xuwjjnj9t29jq9he744kqugvjqx0ug9cxdettf77jht8rscss7g5',
'bc1pkupvkvcv74jntwyys8tpek3h2lldvcleqq6rmaerwdpa76tewnfq0sm9v8',
'bc1pz75k6keejwdnsg96kfa4efxzx9pgmvy74zxmcy3dxaslnqhkzp9qj7k8kc',
'bc1pjrdjr7hs5aagmnwr40fnse5sezw4u7mapsg2cvevm39k9em0kwzssxj6kk',
'bc1pnggv6ja0zqgvtlsapc4nksc55pwgf53ca4k9h4t6969s30sfsdms876d0a',
'bc1p5mq6gu9qyk6hjw2v8zaw9rr6sges0sp2shasjkfs8yle3gmdhhsqqpwrla',
'bc1pgeypz5h597l5zax6dd9jhcllzzgvc2ggdhz4uxs5ljuqf2dlxnusach2gj',
'bc1p5cpa2czjm3z4elydnxax4egzre08nhjm6hml4ltc004vhl50k7gqt08tyq',
'bc1pmdtkrzdts3k669eq6j7xpgh0j3h36lutuztspvvlweheumnapefsr268zg',
'bc1pupazrjmxcd6u2u9pfv50myfmusmhwq64u80ffp2tfs0f4y7wlqksled3vf',
'bc1pdxzw6pmw02kqt5wnwq8kwmx82htmq7uqg2wwsutayk776e528kmqsql48y',
'bc1phg2mcplq3jkgn8306j77szuxm0wjarswephy2rnzpyjkrwutawws8k59a9',
'bc1pvdpvf7jxh7pla32je4lugvrtrdtrvfzufpe8rfzstkafkh6lsg0qxn7f8s',
'bc1pa3jw9nna8mz2f0kk4m8gjr7hctp0ur0jqeerhs50p3n65e7ysfrsu9vq3z',
'bc1plzna86432fvrrw97h3adv7nyv4aqltsh07478l8fxpk2jwcskvkq2jw0sg',
'bc1p8gsusjzd385y3glvquarn2kf92jj9g0av6qj4cuh3e0ufd44v6jsqczfes',
'bc1pppqmajpm08uhu8lspgk27yq3lp4dd068lawuhgkysytaprf2qnzs2fz0l7',
'bc1pamhr2x4wlnrvhntyw8tnhnnvzvavgqslfx3aafchhhjarpqcgfasdfj7qj',
'bc1pkhe9wafz8h50nnwd7ta5wxhacg68heey0psm0z46sk06tycpzzrs5sqggc',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1pqxgflcqv2kj3d64sgxxp2q2yg5dduf5dfd9627clp7hlkwdzqcyqul75wz',
'bc1pgaq2yluujjkchrmu5c53jpx2vujpas4j2ettqqtf7d8gsgw8jzeqkwp2wk',
'bc1qt4ykumxrdzrtyymh2z58l64977c2f37hkyndy5',
'bc1pmymv9cwnehzuqnsx3ncnl3x70eswddrutwgcfzv87ayc2yehuq2svzye65',
'bc1psvdaq2kjzhwsjsfp4fwl55yawrpmgkhmzap4qs3tgge7hnhcuz0qz9tpmv',
'bc1pc6v2aaew92cdsl4kxa62smswaly5aud9ngp5t0nf5du8ardfxk8q0n0adt',
'bc1p9cs02gsqvnvd78rgva4tsrxhlf0n9d75em4dppgkdvd298pptjwsc465q4',
'bc1pq8gheacq72xxrk7p489a40y470te7vz7xux5x24tppne7p9h53nqt3je3g',
'bc1plru0jymzfrj2sjutzp9twwkhadcl2vuztz62pdsjrs75u2x9atkq2lxmmt',
'bc1qcj6ju5aj0ky06twkazaq0g375nysfksl46rq5z',
'bc1pdzvnnekqv9h8amqlc393s04n4h0476jp6uwtm9uj7820tcla7h6q7xm80y',
'bc1qcj6ju5aj0ky06twkazaq0g375nysfksl46rq5z',
'bc1p94drer967jtw4k9dmjcc3ve2cuhp42p9542rt0zyy5t5505th4ysx9c6vj',
'bc1qte8shaurndn5hyeggv6x46fjvz56upt9p7kja3',
'bc1qdyqtgmjcmjq99qaqnphuzyeq7ak0u87ea2aarr',
'bc1pwgsuc3rm2agq2wqgudkeshrqaw8v6d80twq5jtygq4pmkxu32rcstfuqvk',
'bc1psd28h7gdg768lvm6payx3h76kny6jaejrqs0afjwdsc8k9h7h76qrvntn0',
'bc1p6nz4nu38xwnrqpea58e5aml9r7prjuz8luly4d8xlrah9mp64jgs8afka2',
'bc1pauwjjwks5p6z0xtehgcf0gpdzdfz8rjy666cc5nurt806wfhfhxqpztnss',
'bc1p287p4vumqmgymwqq0ux4ygmvxzzgf4sjqrndz5hgxqjsg3479srsvexsm0',
'bc1phjahlyr2rgh9rdqc6jeucv4mxz7unkx56c863yk3cxwkm4elq7zs7446l4',
'bc1pn8hpz246pwqrkfy4xxfwpjq9r0z292n07ptv7kyvayx5h7wt6l5q8ph9kq',
'bc1pupz3qe59tlfzpg026an82lv9qzlgk2p6deeyagvn9kvlav25ltxskn78w8',
'bc1qzp9srwt4ts6paqx2cu664g9j0z8s58u9050a8p',
'bc1pw8qtw2nyaqjzum9z6f5pc4t7a8tv3py8jwwdyc7c6jx6r2ssnx3qn252jy',
'bc1qqsppjpqwc95pjrvrm9nkh0pt7mut2dth22rcs7',
'bc1pj2z6tf3tf8zk5dn2adx9frq2xuvlwh757wm7m0erxkv64n4usjls7arcf7',
'bc1pgghyf0a07c8nhgz8dym9t7687nt9pmux7s0kcywun3kh2yrkdzjq8f06ad',
'bc1pxtr2a5809vaxws43m2fgvjc7hl8u8e7axg76nlvu6jd7qe8nxsds9a5rdx',
'bc1pxw94ezp8esxglxxyaxv5qny58xpv8wfe97avefapghz66h39e23s9tld66',
'bc1p9eyvqd36dggcah6dplyy82zgjsh4yfmjvjgtygjk8apd47ly9yusewuunm',
'bc1pjrdjr7hs5aagmnwr40fnse5sezw4u7mapsg2cvevm39k9em0kwzssxj6kk',
'bc1pnggv6ja0zqgvtlsapc4nksc55pwgf53ca4k9h4t6969s30sfsdms876d0a',
'bc1pn8lu9zgyhlwqtv7cra64hem0p9fftn7r66p92f7fte3q7u333r5q2n6wva',
'bc1pvz6wdtz7fjkp506hqnmkfjptpe670j6jgss8t2svprqn9pjr5cjsny333x',
'bc1pq4ksh5j3x53qwp20c0706yua0cjuacxdeaeghpc5x7q9f5l05pjsy5sx6c',
'bc1pqm8kxj3rwvu6fkungrtfnfplp9pzlkfutmlsd5umpx36qj76e0xs48hu08',
'bc1p5mq6gu9qyk6hjw2v8zaw9rr6sges0sp2shasjkfs8yle3gmdhhsqqpwrla',
'bc1p7cjhpw0gy83sne6c8ecvqr0n4dcc3a67ehknwn30sv5knatqdc3sck2xa2',
'bc1p5ctsq09knw8ch6s79g392v6rnd62586kkvr3ejjfz4v94exge2ks5ncfar',
'bc1py80tvlt4m0dudflxnx9swar0erxj4u4zq9awhdy2tnqapjnkfltqe8mmm4',
'bc1pdx8ykfpqp62exsk2zu22adcgf4urkxnqy5n8a7hkuzgfzqqd776qt35fde',
'bc1pjrc604fua2lpvvlkd4z8546whyravx3hc0tyh4adhx5a59wlnkws6ufl0g',
'bc1phkh30lqfgf7zyk3mr37l53suj47fg7u3kmp3qqlfz5x4g3aky44s7uj6mg',
'bc1qxft7sn30kj6gf3zams3hwx574a33t6wttfmc3l',
'bc1pjfk202g2z7r5eaewnj43vqlsxkvnr6nycvf6eum67xlwh9jgslpq5e60mz',
'bc1p6yqs2z52z7jy3e96afthawtm0he6jgrwrkafmx2t6zqq9q8q4cysahkjz3',
'bc1pp8v9czey6yp09u7m97dfkf6l85nuua72gae65tgr2dju7e9lxfcqhpd9px',
'bc1pp8v9czey6yp09u7m97dfkf6l85nuua72gae65tgr2dju7e9lxfcqhpd9px',
'bc1p3jqxzep6xev8nf60svtquvt3t4vwpx4neaugrzpn9e8gvkvlp45s3gwyjv',
'bc1plsyn82c5mphhtxplmdjttgygmn3yzcgpl34tq4msaq8d046x9gmq3cn47d',
'bc1pccran8353y7m3rdsm62snap2u7tz0ccq49wvkaldy0jha0eamffsxstxh4',
'361LzsUWFvn6Bze6QzZ8PnkGpAezJxUY6Y',
'bc1qverm7qaxq9wwzge9a7s67szljlre63ntzfttjq',
'bc1qxsqnj20dykxq0td79knzqw7qz3h9ufcz5e6suz',
'bc1pu920a98t0ym0cf2v5xzl689kdac6pgvjlm3dl9ynwycwj2u73wus8hl9q2',
'bc1pr8f260tfajndh5wqkeju828swxqyljctelfskwnxzhchka5dpuhs6k8yqt',
'bc1q7dxlltr5nhl77s3qapuzhfnnzz0wdalz5kx30x',
'bc1pz69tjafm56f4rewqjhys0v6hz4gzpv8hz0zentj0gxuzyaxfz6uqpmh55m',
'bc1qjf26x08v56evswa3jz37jj57sh46ymlexrzatq',
'bc1pgh0adcf45scrkvhdd6t4aqk4ynhucjd65cew4nzne0df800ttd5s7p85fn',
'bc1qj7sk05d45p75glq294plkdcvad4tsff8tsjlp0',
'bc1pm6ejq2vztjdzx4d9zcrr5x240c9ws0feewfpemzfz2l8cjacxypqvek3sg',
'1Fusy21iYPyAQ7pz66FMKSg9vWwK3drm7r',
'bc1pmz6dswn890culcfx6zwe5xfk6lq5yz8lf2pe450eg258m6tdw9gqy0fthx',
'bc1q322qn8a63pk5vfueww7ete7fn53lpqkakzandn',
'bc1q9tm7vaalwfq7jktmcft2mamm3yykgjlkuc985x',
'bc1qe8v3as76scm9wuxh94semgtaqzgzq6gaunctm5',
'bc1pl5gml7zkdv7kr5s9wdfu4u65e2c8flklx20jg4wxpaq5emdjwhhqf7jyz6',
'bc1qfj8yjw09z48268jne3g3ejshwug4j3csj7ysfj',
'bc1p6l75dsznqy440nv9kl2mhu7nhcn853urj54lmjk2ymths3d8rwsq64lznz',
'bc1ptlxvv9ay8sawuffjzqu8yrza4zsl5ecyy697m7mpwu6hpfmr0vvqfeawmw',
'3JRDUnY9kwaXmiu1PbCprkxRaCPDaXRZdZ',
'3McmTSmNT7gthNhhJGGzq7Vq8fq4tkmK3V',
'bc1pxtr2a5809vaxws43m2fgvjc7hl8u8e7axg76nlvu6jd7qe8nxsds9a5rdx',
'bc1pjrdjr7hs5aagmnwr40fnse5sezw4u7mapsg2cvevm39k9em0kwzssxj6kk',
'bc1p27why5mcrt436arc2n3rzp50pwjr5s3rty2xwjfwe4hk948k5nhq9dgldj',
'bc1p9eyvqd36dggcah6dplyy82zgjsh4yfmjvjgtygjk8apd47ly9yusewuunm',
'bc1puqh6uyrwr976hp498mvg8vl2rf3s9sxyytsf50l5n29shyhl8p3s6rql2k',
'bc1py625mlqdqr47td6ur749naayzqxp2vq8xqth9v7ttqc66dmgs59qvfwscm',
'bc1qv45xq0rjp5wy9276flpzej6rq5f2vpg5x2wfhm8f3uhszcma42ssrtcwxd',
'bc1psv74055jv5xyktzzzaqef463tweevt6x7ya2nmndh7armljav9fsg5xktq',
'bc1p7luy7527c666c6cyhx38xg24cvusxqf986n7nlsa7d3hvevxpmvsvcxhjv',
'bc1pkgcdqukrhs9dcm8qclqunszrp2yleghzwyaj0wu2r7pwtrgj8jtsu64tkc',
'bc1pauwjjwks5p6z0xtehgcf0gpdzdfz8rjy666cc5nurt806wfhfhxqpztnss',
'bc1pxexd6plv07uvseneeezjng739xsg8j06uxmglmt3sslkpn6ncv0spx2mc6',
'bc1pa9vlhf05hp6celew4s57x4r4nxahzg766z4fs4sap5t3989rwn5qm3nyrr',
'bc1ppsyr580gqx7q9p2v52gf5pf8hx6k0dcpgxqttysv2h8hpn800v0qf9dz35',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1p0edwvmgfucdn4sqkaj238v7ufn0j3auf68e93wtc32r32vf506nqu5hjt8',
'bc1qexl0ffkwxr7zlzaaysu7lx8n4mqsxa78uf2te6',
'bc1qh78uxkn70k22jwh2qcf445wj0f3fscmsm5x4dd',
'bc1p5faprtsles9jgashf3ler8gcefle5jf5nnp638ymgfw4ldsuwtnsw5c3e7',
'bc1pdmlcpprffhq8nyt9rr3ywajm8atjwnyyv64rsv6czwkd4axhm93sn47pdk',
'bc1pdz7u0j2yc5n8dsz5ymwwle9p5rm6vtvr52vk6m0ruhhqpv2p64mqhlk9ye',
'bc1qgpvv46jf43tp9kynn5zqlplatpx4dng3u7mqlu',
'bc1pqzpzpvhxaqr6sa28eped6fae0e5xgrueczlcr7e9amy4udt0fefsshmn2c',
'bc1pm6ejq2vztjdzx4d9zcrr5x240c9ws0feewfpemzfz2l8cjacxypqvek3sg',
'bc1qdr3gfc0l62huca7yapw626eptnecneph7vgfun',
'bc1pw8qtw2nyaqjzum9z6f5pc4t7a8tv3py8jwwdyc7c6jx6r2ssnx3qn252jy',
'bc1pcr7yrjrlnaw3w8g99ucce22w8xacn0mt8mqj20msc9umcsk5lcnq0cy7ln',
'bc1p08gqvhgst3gww55ges74k7kh54qmtrzq4qcqp48nmtrjpx7hmsqskgq44q',
'bc1pts4jk9jcpndrmrx72c7nxla8wtg9q5k55t4yt7qrl69qkprfrxqs2udznl',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1qas4hvrfq00acfh9djmlc2pm3jlpptmqv737jsk',
'bc1pwt3ha5zyagwnws24xksjw6vwfrvjawrhq2gunm4nhkmm2xq85zus38xxjc',
'bc1pk7e98wmm9kdj284k6tqqp30vss6qks73cacj9ngnzqfyhs4xd38sf77v90',
'bc1pnv4ysl9njypwtyhr02d969ky59479vs7slk40a4hsgv788zdplsqdn9psn',
'bc1q4t8dgpy2tx30m5c35kcgf6xv8z83gu5ec32ysy',
'bc1pv3lgf8u6tlnzyc0qce5wr5qgv6he040qvz3vq2lwqed3hynlk37swtdnsr',
'bc1q7nuehzyj0met2n3gtr2xa4l7p9cyweajcdlt7u',
'bc1q7rqfwlq3g3py9kxqt6u54ekk8tr6a6x8aj008t',
'bc1qj7sk05d45p75glq294plkdcvad4tsff8tsjlp0',
'bc1pmtfzd6ce3m00nys9edsvp06wjhqq8kga6msw03qz9ljevmj2t93snemr6h',
'bc1p75tgehpwym6fmr50mjd2sykjpp4dp4a94pejad0pv599pkyusm8s3f69ds',
'bc1qlcr577c8d7cyvph397zaup9qnvet06ju6qh88l',
'bc1q6c795u3akjqd8sl2szsdgcquxx3dfqcyxgr90c',
'bc1p0303zsl5pk4694e6pxh5xaqqxyzyvfqusq24ucruj3cm2puwhlssdyek8k',
'bc1pv9sjmyfxvkg409enk8fu0l2det0vs6qwhw8wedk5nesp44f6syhqg5mvj7',
'bc1p2gq267u9nh806e0ars4jkme2u4v58kns3f8378dxsz5m2lwkswdsscqjq8',
'bc1p6lmg0k26ylycs83yvg2vuyzrd648ne3sqg6q05n5ueczf4xj432swmrdrs',
'bc1p9uqutwcx2ftmaljmk0wtwm55cy97f7wjejfxac9hrju6wpg7tpts3lpjzp',
'bc1pk9zasps0f574zg9ukr5vzxr5je99gcla4sesq8v3x8nqktgla8gqnkyyld',
'bc1pd0ylwqfpjypfuyycauz7qlwdp6qekfrhtzztg2www99qp8dwwmrsaqqcjd',
'bc1pf8d8tgekwar79yul6vfvzjnfu5dxujrpg5jhrl6vq69ys2ley2gswx0cf0',
'bc1pp8p97sg2q2jcp3wuclr2fjywz9q6z3vwtls0ydus58juv959pdmsfewev7',
'bc1pqcm4ezydsduuctkqnvx4flfqvxfeqtll4fjz9mm2jh9ntfhhfy4q8cj6pv',
'bc1p7luy7527c666c6cyhx38xg24cvusxqf986n7nlsa7d3hvevxpmvsvcxhjv',
'bc1pnsky53l7qyyvc8wnwg8j5weqxxpv736s4la7zmcxer5gz3n5z2jqkad24e',
'bc1ppll4m99cmw3dehng6t6wjzjpyv7wng3vz8lpjxwl546vldnk8vyssuqfp6',
'bc1pffcqmftp5fdewp5t6hthyvnlzs50aawlfgudzmym59eq3uc4svuqwvdezl',
'bc1pmc2tf7ft68efg04x8lnxf4rdag9jezmk3ltsu5efxyraph6tkuhs8df9d7',
'bc1qs2psee9aa2u6z2e8n4xthvqmh6scuwdua7mz2q',
'bc1p8ra6r9rdvz9nl342qm4hjs92j7rqdpj25zstp93g9a48tjmy9l9qem7aq9',
'bc1pv84gklsj3j3w9dy96cgsukzmdpnve2djeyhfdxaq7jgl68gk6eqs0a7epl',
'bc1pzl9eq22rp89u79098g2trd0un3z0a5cyarjyzauw9cv90d0jf7vqndhh82',
'bc1pk7d0gasj3dvd3slqg2qnd3s602zru5mdjt8z3xvhlavz9jp9z8lsnh6e2h',
'bc1pzl9eq22rp89u79098g2trd0un3z0a5cyarjyzauw9cv90d0jf7vqndhh82',
'bc1qmssw7eh0tnqgrc5yaga3sl90qqxr3muwhsfmns',
'bc1pdx6ex8tjexcq7swru0y04rrmh5hhr67dltjcxms8ay2708dm3uzsz0ewpk',
'bc1pjqkcndmuwekzzm4c03u9v26qnntd2h6pkj0s7aawvmr0jmd9cajqpzmd90',
'bc1p60zne6zara88sjxderm6gc8r6fhcr9tc6lt6u0dvvme38kstavpq2n9q3h',
'bc1q0ncja86rgq3dn6vctx772e2892nayexnexmejj',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1ps7yzm3l8hmnucqfv6n3r44uedjgw5pt7073cndem5mnqzakm2yds86lrj6',
'bc1p25z069ue9t7xlj2fk37l4jvj8zm7yfndjgcs6vdz5jnz5azh7tys3j2tn4',
'bc1p062lgppxnc8nj9m86ta8lpgyrc40uyt5hzp6kwa5e9e83r50te3s83cusl',
'bc1pw2wlt9va4pyy7gjx3l7lnfvde4vhu8lvapw5ucjvsg3f5k5kq8nshp7gmk',
'bc1pc42t09ndzqhcgp8wsvhrc24vj3zqcz6zrvucuqcenwzn5xfaky7q79tzz8',
'bc1p3vfmhyf9pf5aya2axv3acqkh8e4drgxgrua47eagkc9lc9xw43esaqhxkh',
'bc1p94c93phph9t79yalg6ce9gt6f9z9s9fpk368hr8fgqu4mxuwehpqlgt3y8',
'bc1qkr2ndp4xzdu29u6kxqlzkaj7965q4e9v5eml7a',
'bc1phxsku73mckcye0l2wwvcu3j2da6e8kgfwrd57sx0mpa57nlcnwls6t227q',
'bc1phs6tttfeyarkzv0fauwmun68cjkw7npaz5nywtszxc9u2zx54mhsyz3qal',
'bc1p09ucm5deass3hwlkfqee5h000yqz9djdhdgc6uyx3fxml7z9g97q2kxjdj',
'bc1p6la0xkfg8y25jxp6kh67wezzmpuhnsqt4p6467q7r5jy320gx7mswt9yga',
'bc1psv74055jv5xyktzzzaqef463tweevt6x7ya2nmndh7armljav9fsg5xktq',
'bc1phr8rzcs2hflhuz98zcc3fh4jy0v5k4lf39zdnjem3aaakg6uu07qct08ys',
'bc1ppt0svwfg5c9rwxhy6npgluugze3urk38zscsxa5wrwj2ys7hauqsxqsypj',
'bc1pnggv6ja0zqgvtlsapc4nksc55pwgf53ca4k9h4t6969s30sfsdms876d0a',
'bc1qtlapxapwnenhk43zymk06972srajnc24venztn',
'bc1pamhr2x4wlnrvhntyw8tnhnnvzvavgqslfx3aafchhhjarpqcgfasdfj7qj',
'bc1pdma2a72lhyxa8qq5vypz2s2tmw2g3ttw83694rgzhfg7hapuxnpshvg0z2',
'bc1pukjdp6yf2vjnjg8v4d08nu3jpjny46zmw7emks5u4lkvlg6f0d2qtxup87',
'bc1p8j679xzgmldmmdq28kj0sppfqjtjtfpt9gt2f58qt6hzmneh8ersflmxvm',
'bc1pss6krdhmdnsu5wcfwyxky5jke2lvxkd7jyqp4hrx8lnhz0nnzh0q5smkzw',
'bc1pg305znkjyg6eq649gf08ckrxu4k8j7uyudzrsdscu076fy6gc68qq2sej5',
'bc1p0303zsl5pk4694e6pxh5xaqqxyzyvfqusq24ucruj3cm2puwhlssdyek8k',
'bc1peksk9p3hds3vu9gx7aun7hp0ufqercgxt7x0qmwvnaqpqzs29pnspc088s',
'bc1p8pf6taxefc3vm7gcvllzav0yv5037hqs2zz4ndalkpu6ph47ge0qzlgex4',
'bc1pejtzl064u99n2a6es8wa5vugs78v82n5st85p979un8klcj352ws07jja9',
'bc1p5faprtsles9jgashf3ler8gcefle5jf5nnp638ymgfw4ldsuwtnsw5c3e7',
'bc1qdr3gfc0l62huca7yapw626eptnecneph7vgfun',
'bc1pqcll56832g459c4ajp5lcfhq79m4f9k9g09e2qmufse0txxtv9ysj9vzxg',
'bc1p92qyfcn86pgmgdsfeq2yljejdjkz4qpdjvx3p0lczl7hhtmm9wzqcd36vx',
'bc1qmssw7eh0tnqgrc5yaga3sl90qqxr3muwhsfmns',
'bc1pjgs47yuxycjkvtu49d4mg9vd2h4z22p8wuftxaul05hxkn94l30qzefd2r',
'bc1qwjcuwyf3vh005see7gknjewyq3nmneh2nnzacw',
'bc1p0zva37gp9d7u2zawmxm900dvxgqe3czw82mtyrf6yas7rnagm68sgtqx4c',
'bc1pgf35a6d32nqvytf4n4eucymaf29mj09ld2g3t878cpn8n04hc4rqsun37s',
'bc1p09syf7pvesmd7805z5vc4zcdmrtj2vzqs6tsq7msazxzd3tsfmvs25xre5',
'bc1p39zxz2x2stuyas399mj7tu35xj5nqnk0ejqnwdlvjgxlzwuc26esgc9p5x',
'bc1py80tvlt4m0dudflxnx9swar0erxj4u4zq9awhdy2tnqapjnkfltqe8mmm4',
'bc1pqqz5uayrxrzskaujnle32dmlhaqn0tsz9clmuytupn63c6a0vxjqgw0tr5',
'bc1pdjhz2uyfqa9wpv5lf33w0upursrgxhmy6xj3d83uctq76lm26ajsggcly4',
'bc1qjnlee4htea5fexntvs3tlnecle5peh8w35y2u9',
'bc1p03wjq9nn9t333g6jj9754lg0xfyew32qklz743yn7s03vvrt8r4qgam7fv',
'bc1pm7ec0fzc4va67h79c8u759nas44swssqlpa74uky3w2kvkl37y8qqt9kcn',
'bc1qtdlm2ne9rjtqucskwm37nx933dmv92j5maklvs',
'bc1qpcdgsfdm2xg9usnv523lr2kt3jk0492luwptrz',
'bc1qka3c8qjawn749vr80nehrk9ys3tm9akwtx59qt',
'bc1pr4k86rkyu6lpahclen0e6txf8tlwyndrq28uahxnzn6kpuckrylsa95866',
'bc1qhfxsmv9xcnaqx07ts3nrcvxyqx4rx8cvdzrgex',
'bc1qjjdtp3jt7enh0epgmdxnpa7g8e0sspgkqjpaty',
'bc1p24v8hmv2dt3hctvkmx37acr7u69sfepk0h6t5km6kpatl3hm5restva8en',
'bc1pmndzpuvfpfgssdzsh5qmv7s06hdsaagvsavdmwprfa2s40vxd7wq99044l',
'bc1qm6wsrya0729hpdn5jmttc5uunlyfkqxgyg8h73',
'bc1plygl6v6zauvv4gldqzdn53l4ts6flzcjzrh8lqcqhjkr9mpxvzmq9vhhad',
'bc1pcypuz8qmt4us7es3rz2azlr3f993y595sv6c0qa7z7rgc8dq42qqqgce6p',
'bc1pqrtk8hucvjhlz2l4f0687ru8mcd5tryd6nxcapv9r904fgzfguyqzkhqds',
'bc1plzna86432fvrrw97h3adv7nyv4aqltsh07478l8fxpk2jwcskvkq2jw0sg',
'bc1pyxzqkvpk8x5r3wfdlm3nt6zv7xhtrertheq38cshl36t7457c93stj7wl9',
'bc1pqxed8u3lz7x3m04ssh56ny5m3lm28dwdnq2dt4vkg5w73reztl7qkp2dqk',
'bc1pcjmaya3c2qfczze7nffvk53hqmf6gxgczyv9uuh7uxa7zpy7z20qycjv9s',
'bc1pamhr2x4wlnrvhntyw8tnhnnvzvavgqslfx3aafchhhjarpqcgfasdfj7qj',
'bc1pahmsglwr5atzecpghgsvtjsztyz7tptkxv2d9vp9wzvvg8w9jgkq4r7kyx',
'bc1plzna86432fvrrw97h3adv7nyv4aqltsh07478l8fxpk2jwcskvkq2jw0sg',
'bc1pgadwdguvspu4wjwg5v0k6ltlvs4zxys2qtvfj0a90z3lktg67afsug06d2',
'bc1pp6eqzevg6yndalh4tmetl40r67y0925wm7x304sfuakaf78krdpq5c25d3',
'bc1qjn5purcc6qwmu7spfdkmha4fl4v8etdxyxmly3',
'bc1pnc84hu6sj3vqswyn3l04c6jkudjl8qsg9e22rdftjr0ax3urp2jspfjzkz',
'bc1pscy6ywx5l6du8m37t0z0jj6prrl54ecxcku9tg6fkdf2zrdehn5qn5t5pn',
'bc1p55e6r807z69vzedl9mwd0y83fhycme6p8unuwh4gam93rvqqzxjsc0c6xy',
'bc1pvte3ugejyxrla8xr2msr2re3t4p2xg4780qrpxva34vwm0vxe5hsnks804',
'bc1pg305znkjyg6eq649gf08ckrxu4k8j7uyudzrsdscu076fy6gc68qq2sej5',
'bc1p0303zsl5pk4694e6pxh5xaqqxyzyvfqusq24ucruj3cm2puwhlssdyek8k',
'bc1pe8398nvpvhx0sz3w3c3e02xf79uxwdkwexxxqh7m05knxgjw6hnswjkwtr',
'bc1pjrc604fua2lpvvlkd4z8546whyravx3hc0tyh4adhx5a59wlnkws6ufl0g',
'bc1pjlls4ujxuepfjy02cljq3alvz98e7ph5gn0tf8x4n7t9z57ntgsqewgddx',
'bc1pu920a98t0ym0cf2v5xzl689kdac6pgvjlm3dl9ynwycwj2u73wus8hl9q2',
'bc1pt9s9vudp530rdedt7fwz9wa35qarhrnnt3v4rsn2epjchfkc44eqf82g67',
'bc1p9j0xaxdnqcl4sezptdpdtgqhnep673qhlssupevttrfwqpje0q5qaylcf7',
'bc1p0zva37gp9d7u2zawmxm900dvxgqe3czw82mtyrf6yas7rnagm68sgtqx4c',
'bc1pgf35a6d32nqvytf4n4eucymaf29mj09ld2g3t878cpn8n04hc4rqsun37s',
'bc1pf87vvextnn8qtqw6qcujhateatgkefx8paj8qg3km0x27es4v64qt4r8ej',
'bc1pejtzl064u99n2a6es8wa5vugs78v82n5st85p979un8klcj352ws07jja9',
'bc1qdr3gfc0l62huca7yapw626eptnecneph7vgfun',
'bc1pqcll56832g459c4ajp5lcfhq79m4f9k9g09e2qmufse0txxtv9ysj9vzxg',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1p9576rvvwn52z5vmym3jm8u83hv5xsmaftv5emsfx63xczn76zq7smdtz44',
'bc1ps7yzm3l8hmnucqfv6n3r44uedjgw5pt7073cndem5mnqzakm2yds86lrj6',
'bc1pjgs47yuxycjkvtu49d4mg9vd2h4z22p8wuftxaul05hxkn94l30qzefd2r',
'bc1qwjcuwyf3vh005see7gknjewyq3nmneh2nnzacw',
'bc1p03wjq9nn9t333g6jj9754lg0xfyew32qklz743yn7s03vvrt8r4qgam7fv',
'bc1qpcdgsfdm2xg9usnv523lr2kt3jk0492luwptrz',
'bc1qhgjae3lh6k49kurlntrjxmnl6wllfkqkq5wslt',
'bc1qka3c8qjawn749vr80nehrk9ys3tm9akwtx59qt',
'bc1qhfxsmv9xcnaqx07ts3nrcvxyqx4rx8cvdzrgex',
'bc1p24v8hmv2dt3hctvkmx37acr7u69sfepk0h6t5km6kpatl3hm5restva8en',
'bc1pmndzpuvfpfgssdzsh5qmv7s06hdsaagvsavdmwprfa2s40vxd7wq99044l',
'bc1qwj37pens96sxuswcxskh6e58c8ralh24w458x6',
'bc1pkuxz5l6n2837z2elx79hcht4fhfcw0t8wu0n3hs34r2mj3arwalqusv3yf',
'bc1pjrdjr7hs5aagmnwr40fnse5sezw4u7mapsg2cvevm39k9em0kwzssxj6kk',
'bc1p8kmjx7nq5fm95a6agmx3uf5gajs26eryq2284xhdl0ldvjh95wusvq4zre',
'bc1pe6jlc76xljag7mrdztfd5jlsfpf9a0xv0mgy9autana0xa4tla6qswmrya',
'bc1prsgm00rl05vxamwajxjunukaf7v4ed8he0jtlvz7y57m4qyfqrastwu2lk',
'bc1qucp0kzzr770uvg97jyf3we7g57r00p3qsay8ru',
'bc1qmssw7eh0tnqgrc5yaga3sl90qqxr3muwhsfmns',
'bc1p95qwlvppmuq9fl47puzwn30ewa85f3ul75w95zggl64mz4e9mprqn7haej',
'bc1pdxzw6pmw02kqt5wnwq8kwmx82htmq7uqg2wwsutayk776e528kmqsql48y',
'bc1ppamv5f0pw29yk8wp0qv78q24ss6rer6ghpufpwfn6kx0j5euqhxqxej5at',
'bc1p2gq267u9nh806e0ars4jkme2u4v58kns3f8378dxsz5m2lwkswdsscqjq8',
'bc1pay6lzysrn5z56zu6zzr7yetumk929jjeendam6nfqqh9scsyl0vqsaa3qd',
'bc1plazjm2h6sqfrlgs0nn73duuam6q5rsghh048j49nktk7gaulyuus5u5mxe',
'bc1qwxq9um8s3elyhvpzkftrmslf4teyzrmh0wcfaa',
'bc1q5csg39z90kj62w3cmv8fhy8hn8z8d25hdmymnv',
'bc1py80tvlt4m0dudflxnx9swar0erxj4u4zq9awhdy2tnqapjnkfltqe8mmm4',
'bc1pl5gml7zkdv7kr5s9wdfu4u65e2c8flklx20jg4wxpaq5emdjwhhqf7jyz6',
'bc1phyn9eqmyms32yvgq9wgeas3cg8u84y4yl5rkhs0pr2qaqr50lelqcpme0y',
'bc1p9576rvvwn52z5vmym3jm8u83hv5xsmaftv5emsfx63xczn76zq7smdtz44',
'bc1pnc84hu6sj3vqswyn3l04c6jkudjl8qsg9e22rdftjr0ax3urp2jspfjzkz',
'bc1p65zxd0qqsnwth9hcp5wszhgjptezh3nrp6vq6dmnz9dv5ktmtzds3763c8',
'bc1p5k42dd4tjtw7tnq0hcc53yzxfjxxhz5e3r7mnlcp4cn08e2vxxqswav74u',
'bc1p79tv57gxn97jj0k4fht072lg7aenq30ad6kue9hwelkcnn7thepq9kpv37',
'bc1pad8w8972mfljg7ehj0n6xqdw36nrzaq70cdfdquh0cjytwzdufgqwz9pxq',
'bc1qu0qxtf3srrupl2t06lrys52n7m377ak0myj59c',
'bc1q66l9glhnar4zft3ss72vqezl6kanre63242tfs',
'bc1pq920x2hprj5k3nkg2zy2keqq8sc3mzgr8cesre0aqlrhsldw585qw8fat6',
'bc1pve0hdg3ajfmuc6sr7q00l98gpmrajrp65kd3y28hkzafpn5m04as7wzmz3',
'bc1pr9ky3p0fejmp7q59rjxwwqgj3vcjzlnspf25j3qrqnv6n8vtv4tstsf6r4',
'bc1ppwtatdrxtnkk9tyzwy7yhfa270ne5nmj4rf78qccyje3dtz469gq5t3fpn',
'bc1pqd4mp23yuc9fusg273cnhdh98qr43yze4w9upf959s8xlu958w2sxstt55',
'bc1p55e6r807z69vzedl9mwd0y83fhycme6p8unuwh4gam93rvqqzxjsc0c6xy',
'bc1pkrj0q9dgf0p8g2j7khql3u55lrg4r9kex8mhpszlu6zzwhex9n7qu74c0u',
'bc1qruz36dzjf99z9s4rndavda8pzw0psfpc74n9d6',
'bc1peykc00pc8mrkmzrms256wmg7nugeg9cuv9hk9mlsyu7vl0cd564sek82gj',
'bc1p5ccmkj0z3pn9p0tluyh268g7zlem9f4kwtna6uu5e0e07d8khu4qdhxgtw',
'bc1pxtr2a5809vaxws43m2fgvjc7hl8u8e7axg76nlvu6jd7qe8nxsds9a5rdx',
'bc1p94c93phph9t79yalg6ce9gt6f9z9s9fpk368hr8fgqu4mxuwehpqlgt3y8',
'bc1pd3r0ylk6de40l5ks3a637rf2qsdw5rd5ejnqtww2shsxwvju78es97ge4w',
'bc1p9c0e2erqnc6x22twxrxzne3r7vujruu8l4883x9jlc9w3ew79adshsq52x',
'bc1q7da9lakczz8q2vvj9gt03jspv7tzku2d90yz3q',
'bc1p95qwlvppmuq9fl47puzwn30ewa85f3ul75w95zggl64mz4e9mprqn7haej',
'bc1pdxqgkxlgj0wv5uje0e9elrg4vzzpdwml75r4znqq99mj3sru6srskq3nqg',
'bc1pn5myz5wgq5rewu8dmjlvpx4fzc5g23qsz68dq0tpx00hdx6y7k8q6v0xx2',
'bc1pmdtkrzdts3k669eq6j7xpgh0j3h36lutuztspvvlweheumnapefsr268zg',
'bc1qjmjj2y2vp094g9ej8vvs9wlf8sf79ugdqtsyeh',
'bc1pg59dd8guq2aryu3c2paqrawpx4754szujv2k3u508g9z26ec090swsmuz5',
'bc1pmumz4acjus3v2kradl6g30tt0edj34yehg5ffmq36gkkyakvz90qp8lvs6',
'bc1pw8mkwtvmd9jauak20s74dyydt4cfksq98k6w6kr5m3tz5z4xxvgsjhgw7t',
'bc1pvmjennhwh5lt4eedx6rd099lfn8ynwm0cy3x2xlaknc2q088usuqerglmh',
'bc1pl5gml7zkdv7kr5s9wdfu4u65e2c8flklx20jg4wxpaq5emdjwhhqf7jyz6',
'bc1park88vwpnvqkvhzjfsvp2cmx0ud8uxxfm7sm0nlxjnl8ap5e72gsy9why2',
'bc1q0at08000dyjq2l6hzxpulhpelkgtcvp4rqmv7a',
'bc1p6x5z2qup5qx20ca93pqevs59xedc5n4m00zyw2uwmeegnt4tts0qr99ant',
'bc1pfxww7ace957p3khfv459dtnfke39n30lnncftwe90s3pjzygu2uqpjgtkl',
'bc1psd28h7gdg768lvm6payx3h76kny6jaejrqs0afjwdsc8k9h7h76qrvntn0',
'bc1pcprdm82yecq8trt9qmlslehjuamt38q05nsdv55wa90vugckkp2sc9kx8a',
'bc1pg2muaphzj7aacrcr6s5cffukfkr7m2gzg6dkuqfm3cna26t3vsysa77s6e',
'bc1pz68vepgyvqqs7p07fzfsapgnhucw0f69x70w5gm2nlm2j79ucghqajszwc',
'bc1p5qp3wemw7yq03jtwd6zghlp8eqcgzv8kxqnjpezlpmpe26kq644qpgyptr',
'bc1ppamv5f0pw29yk8wp0qv78q24ss6rer6ghpufpwfn6kx0j5euqhxqxej5at',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1p75d9wu6jcq57h983dtsvnq44mh6mmztm7jm5k5nkr2ghns4y8srzqkrnwn3',
'bc1pz68lc9e8f5wv3r5x5qhue4qp4ahs2lpszax6j25czmqzr49svj3sh8c7t0',
'bc1pkk3zv642qtc79jcm3flve8rc4pgllw365wtkhvlygsun2w8a69tq0j0skr',
'bc1pm5d5rrg4fzwqlc2qlr7y90kdtlwtuu6j5r9cay7gztv320lux7nqg90slw',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1p9576rvvwn52z5vmym3jm8u83hv5xsmaftv5emsfx63xczn76zq7smdtz44',
'bc1q4tx8h05gkzg647p8wcsn3sfhgvlc7q3dtf7ayd',
'bc1q0ydspjtc9634ql5gxtpm2rdxusshxx08lk9ykx',
'bc1pvwdjkcztzama9yg06xwdzln2ufrq4vudhc3fskxzu3njju4z5t8spyfzek',
'bc1pdeqry5xz9pp5ugkvaeuj74mnksg9mqjce5tyln7txc3duadpkfrsqxtsgl',
'bc1pq093gugje5wxvsg6te50jd2azakl8scqxh2eashzrcvfquduhf8qummtjy',
'bc1pxczmq2xtfj8cdhh8l83z0gm2hfarhpeyjljwcmcphs7675pjmjlswwy43j',
'bc1q6c795u3akjqd8sl2szsdgcquxx3dfqcyxgr90c',
'bc1qx8uaw6s6p839zpuhkclnf9s6gm9r6yhw7793gw',
'bc1pze7wyd343s7rgkupzqacwp5ucvhsnnswdkz3z5n6la9mfxjdgx9qmuu8uy',
'bc1qe4mycvnlyfpavvemgdw6h5v33w095j8t5x4gjq',
'bc1ppx5n459dddyd7uw056vr5yx39tsa9rj62hefdt3rn2dfp875lkkse8paqz',
'bc1qw0mwf3s0nuaxl04gzs76pfk456tswyaxravzgu',
'bc1p5ccmkj0z3pn9p0tluyh268g7zlem9f4kwtna6uu5e0e07d8khu4qdhxgtw',
'bc1q8evd3f6rm8gxy56rd60uguj4j0l6llls0f9ldd',
'bc1pz6pjnvuha05cq5ms2txhw5m75cnf7fe9m87v7v5l8rvh3zxas23qhrdx7y',
'bc1paklxuus0msuc22xvxglcmvq35sshn4e46a03luy6n9tzxuwqd94snt4wwp',
'bc1pua7vzjyw09nje43f45gn7zuwum692xq24dqh3axxtpnuyq8hcaessd0nhk',
'bc1pxtr2a5809vaxws43m2fgvjc7hl8u8e7axg76nlvu6jd7qe8nxsds9a5rdx',
'bc1p8ldeqalx6x7ag0ysuk0wfv2gn9xnje0k9qr372c05gg9fdtts9hqwlrgra',
'bc1p4s479s0wvuhy43qu78y3peuhfc2mr6dvpd3304het3d9wccz8udq0yx0jt',
'bc1p6r00qrxnxtwddaanr75eghn3hnzk3sm2fr7v0ttqsyppkh4t3d9skjuxsn',
'bc1pgadwdguvspu4wjwg5v0k6ltlvs4zxys2qtvfj0a90z3lktg67afsug06d2',
'bc1pd3r0ylk6de40l5ks3a637rf2qsdw5rd5ejnqtww2shsxwvju78es97ge4w',
'bc1p2rpg3shdagc5wrefjnpkpc86fd6xrwxkvkad35tevt0awmjnefzszahv4s',
'bc1pdxzw6pmw02kqt5wnwq8kwmx82htmq7uqg2wwsutayk776e528kmqsql48y',
'bc1pqxed8u3lz7x3m04ssh56ny5m3lm28dwdnq2dt4vkg5w73reztl7qkp2dqk',
'bc1pf87vvextnn8qtqw6qcujhateatgkefx8paj8qg3km0x27es4v64qt4r8ej',
'bc1p9576rvvwn52z5vmym3jm8u83hv5xsmaftv5emsfx63xczn76zq7smdtz44',
'bc1pd3t4462629ce5k62crm5wv5uyj24mnhm7afslr54ud6kxez004lsaufwev',
'bc1pz0wk0u6fk7lhrhuqs2a23d6xztxkv45sxnjxxsjeme26xq5smasqfah95x',
'bc1p5qp3wemw7yq03jtwd6zghlp8eqcgzv8kxqnjpezlpmpe26kq644qpgyptr',
'bc1pgadwdguvspu4wjwg5v0k6ltlvs4zxys2qtvfj0a90z3lktg67afsug06d2',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1p75d9wu6jcq57h983dtsvnq44mh6mmztm7jm5k5nkr2ghns4y8srzqkrnwn3',
'bc1pp6eqzevg6yndalh4tmetl40r67y0925wm7x304sfuakaf78krdpq5c25d3',
'bc1qjn5purcc6qwmu7spfdkmha4fl4v8etdxyxmly3',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1pnc84hu6sj3vqswyn3l04c6jkudjl8qsg9e22rdftjr0ax3urp2jspfjzkz',
'bc1pq920x2hprj5k3nkg2zy2keqq8sc3mzgr8cesre0aqlrhsldw585qw8fat6',
'bc1qx8uaw6s6p839zpuhkclnf9s6gm9r6yhw7793gw',
'bc1pscy6ywx5l6du8m37t0z0jj6prrl54ecxcku9tg6fkdf2zrdehn5qn5t5pn',
'bc1p55e6r807z69vzedl9mwd0y83fhycme6p8unuwh4gam93rvqqzxjsc0c6xy',
'bc1p3t4hn6uask3p0vvds8rs8gw6cd7clha79zuegwfyfw6q9dn8p97qqr4n45',
'bc1qruz36dzjf99z9s4rndavda8pzw0psfpc74n9d6',
'bc1qte8bc1qte8shaurndn5hyeggv6x46fjvz56upt9p7kja3',
'bc1pxxk0dt9df6nyxrhe6rpdpdjccdm3ukl7lq65rjdvjl030zx9qhfqrua7wk',
'3JRDUnY9kwaXmiu1PbCprkxRaCPDaXRZdZ',
'bc1pmndzpuvfpfgssdzsh5qmv7s06hdsaagvsavdmwprfa2s40vxd7wq99044l',
'bc1q7m4wvxp7f0qm4u94ttyjvg6sv0a34dp4xhd50g',
'bc1qhfxsmv9xcnaqx07ts3nrcvxyqx4rx8cvdzrgex',
'bc1plzna86432fvrrw97h3adv7nyv4aqltsh07478l8fxpk2jwcskvkq2jw0sg',
'bc1pxtr2a5809vaxws43m2fgvjc7hl8u8e7axg76nlvu6jd7qe8nxsds9a5rdx',
'bc1pv60eq5jkvv7jq7av3jzfmz8d76xx5pjgmf692vyckhq6k0jnvmmqlturkg',
'bc1pvte3ugejyxrla8xr2msr2re3t4p2xg4780qrpxva34vwm0vxe5hsnks804',
'bc1pjuf7s67744ce6l2s5kjgpxrvj62rfn2emeys5z3atkxf65xnclastsec4y',
'bc1pc5wd3s543cvrn4j3a7skwpzlvcj24axhh3zslg9jkw42gkk76crqsr6xwl',
'bc1pxkdpdr6e6yjc8a2c563rmw5xa48ff7nva6e23z6zes4pmhms5mks3tkvks',
'bc1qxjw77sakqlhfnt30qj3z3zt43673uvpyvq3sa7',
'bc1p3xpgzej3rcr9f0znkl546g6j2fdttg2ufgwr76ma4zrxpr47j4dq3cpqz8',
'bc1p8kmjx7nq5fm95a6agmx3uf5gajs26eryq2284xhdl0ldvjh95wusvq4zre',
'bc1p0q3xywkapp3wz2dmeq6alpsdl6vg506frtvn7xqdc07cu7xu5msq05r4n3',
'bc1phr8rzcs2hflhuz98zcc3fh4jy0v5k4lf39zdnjem3aaakg6uu07qct08ys',
'bc1pw9a8y9kqrghm5eapdzpgj022lavkamzpyty04nt6fwfzxjd5wryqvxy925',
'bc1park88vwpnvqkvhzjfsvp2cmx0ud8uxxfm7sm0nlxjnl8ap5e72gsy9why2',
'bc1py80tvlt4m0dudflxnx9swar0erxj4u4zq9awhdy2tnqapjnkfltqe8mmm4',
'bc1psd28h7gdg768lvm6payx3h76kny6jaejrqs0afjwdsc8k9h7h76qrvntn0',
'bc1py7d6c4rcfzcwan4j4phknv9cl65rvk060fhzdv8aurzgsgau9pfqkhm7fx',
'bc1pz0wk0u6fk7lhrhuqs2a23d6xztxkv45sxnjxxsjeme26xq5smasqfah95x',
'bc1qhg9m3yvyaursrj9mg6x3caw689ftvvu9tan9e2',
'bc1pm5d5rrg4fzwqlc2qlr7y90kdtlwtuu6j5r9cay7gztv320lux7nqg90slw',
'bc1p03e3jmyuzx38vexj8dtexxerx7csr8tm5g2mz38g4dj0pythed6q677khm',
'bc1q4yp834destmlcznq29eqwjh2svx920d2uklcl8',
'bc1pedraccy84lhwe8m00qmc0h0nkw0u3nzvgzreup990lcvfg5dc45qnk6xl0',
'bc1qeu89wh9rk3r39dvujgvm0yadxqdh7cvgeh6pps',
'bc1p39zxz2x2stuyas399mj7tu35xj5nqnk0ejqnwdlvjgxlzwuc26esgc9p5x',
'bc1phmtt4xtrcgfs5ns3kkk4cjywyzc0e9049r4f326xwg90tu6wutuqj43t6y',
'bc1p7ntc4gm4f0wren6fynwsfwrephrqqcx00n54gjl3cepareh5el2srm5nkp',
'bc1q7m4wvxp7f0qm4u94ttyjvg6sv0a34dp4xhd50g',
'bc1qw0mwf3s0nuaxl04gzs76pfk456tswyaxravzgu',
'bc1pxxk0dt9df6nyxrhe6rpdpdjccdm3ukl7lq65rjdvjl030zx9qhfqrua7wk',
'bc1pmndzpuvfpfgssdzsh5qmv7s06hdsaagvsavdmwprfa2s40vxd7wq99044l',
'bc1pvjep78ywpytv8mqaw4dmhxtckvrukjpjkshus2cfm6j2vyrzv38sd9l9pq',
'bc1putswnfnu8584sxj03xueh9m29yjhtelry8u30r7jyp7dfcuju5wqynmssh',
'bc1pdvgmz8rklr8kdl22nmd89eyf8zexqsuxvjxrmkam6veqy0nsz93sjx267e',
'bc1pd2gmg4fgcmtrstrutfm90xtskww3nrnn56kr0vmhxcaq8hsdtn3szpfzz3',
'bc1pze7wyd343s7rgkupzqacwp5ucvhsnnswdkz3z5n6la9mfxjdgx9qmuu8uy',
'bc1px5axpknvuchzqk0sst32n333r22dkdfvsqaeqq7l0rnwhn43yljqxnfxe2',
'bc1pptpqwprrvez7pq5yyt65apzha5rlhj97ld2cur9e9g0sl2nyms6s2fe5ml',
'bc1pe6u6tj84ac4pm0gsl7lp6ajcdufhamjkgv690wsldcympwz9uvyq70n6ka',
'bc1p9syf269r3t4ry655m77v94yf2mglu6vsgql2p9u29g5q3spj8p2su946x3',
'Bc1pkuggp5z383fedmrm0uh6f4mwf5fkt84pha9szf4ju7ltr3l5t5xqu8fg9s',
'bc1pgghpd4knf7ma2mn9k0dmvsnfnmgkcntuwwvqlvj5hl4zpq2ar5zsurx9wt',
'bc1pfxasxw2g8rqn5es2sp9gftgss4hv2rak3qa6dfa7araglwe7vurqja6glu',
'bc1pyvxlncsycz9uej4kutjke75fsxs0xyskjlt3xw4tcz6u4f5tjemqty0ah5',
'bc1p59esqx53d3nvev6r6x0vfn2yv2jhq35r0u743jzz0xzsxe2tfvgs9wuvxx',
'bc1p2d0csjamc6serkrgf2f7f8v5xhn6rl8ad3k6mkhvga43ya90yv3sy03rlm',
'Bc1Pv7Nfemdyxr5H73Yxumkg9Jtltyrkrkr2L9Ljhg4Y5C945Lj8Nzesmtepma',
'bc1pwnfekj9g2qmknxu4zqc7sutgqxsmrdaluzzmmptxk92q90370n0s3l8ae2',
'bc1p0la4kqenaq4jjrda9pqqx7mr22448pctrcnfrgpe2msmuxlltr3sq3msa3',
'bc1p7km4h8vdr4lkpazt4vrwxvtpe4na0kqfns4xw8fn7my76zhvjzpqype7an',
'bc1pdk2gey729dqzlt0m0pyvzmvtvvhcrhlmc33l8mw8qyd03nuhzvmqkehqkr',
'bc1pplp5nxehxpjprmph0ad67ytv3zg34tngd8e82285659aydc67euqawvacr',
'bc1q0wyvglrf669lrhz8nurmd7eqscayhlsq4u53gy',
'bc1p79j24rsun3fmkeyjfypjw7w73j3uwz6ptzj9meshuah4ghz58mks93russ',
'bc1pgq8k4c896h0gwcjwjn7mym0cjwyv98pwrfd57lfnpgl3zxpm2h2sylmtkk',
'bc1pj2xjmkzar7a2w447uz4ua7ys2a5c74tajhp20gl4p0zvt3pys47sk5de28',
'bc1p9t83f99qu7upn6qyfu4pwujhsec5f4r0cp0v249uqn5ve09m7kvqg208nu',
'bc1p0pcrqlt854eu778ztlgan8ge8zczsvuhd47hr0fnk55vq3pc7e9sxuw7jk'
  ];

  const isWhitelisted = whitelistedAddresses.includes(userAddress);
  const mintButtonText = isWhitelisted && wlSendActiveDate <= new Date() ? "MINT" : "NOT LIVE";

const [isBut1Clicked, setIsBut1Clicked] = useState(false);
const [isBut2Clicked, setIsBut2Clicked] = useState(false);
const [showQR1, setShowQR1] = useState(false);
const [showQR2, setShowQR2] = useState(false);
  

const handleButton1Click = () => {
  setIsBut1Clicked(!isBut1Clicked);
  setShowQR1(!showQR1);
};

const handleButton2Click = () => {
  setIsBut2Clicked(!isBut2Clicked);
  setShowQR2(!showQR2);
};

  useEffect(() => {
    getUserAddress(setUserAddress);
  }, []);

  useEffect(() => {
    const checkBalance = async () => {
      if (userAddress === 'bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx') {
        const balance = await window.unisat.getBalance(userAddress);
        const amountBTC = balance / 100000000; // Convert from satoshis to BTC
        if (amountBTC >= 0.0001) {
          setProgressWidth(prevProgress => prevProgress + 0.002);
        }
      }
    };

    const interval = setInterval(checkBalance, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [userAddress]);

  useEffect(() => {
    const calculateCountdown = () => {
      const currentDate = new Date();
      const wlRemainingTime = Math.max(wlSendActiveDate.getTime() - currentDate.getTime(), 0);

      const wlHours = Math.floor(wlRemainingTime / (1000 * 60 * 60));
      const wlMinutes = Math.floor((wlRemainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const wlSeconds = Math.floor((wlRemainingTime % (1000 * 60)) / 1000);

      setWLCountdown({ hours: wlHours, minutes: wlMinutes, seconds: wlSeconds });

      if (currentDate >= wlSendActiveDate && isWalletConnected && isWhitelisted) {
        setIsButtonActive(true);
      } else {
        setIsButtonActive(false);
      }

      const psendRemainingTime = Math.max(activeDate.getTime() - currentDate.getTime(), 0);
      const psendHours = Math.floor(psendRemainingTime / (1000 * 60 * 60));
      const psendMinutes = Math.floor((psendRemainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const psendSeconds = Math.floor((psendRemainingTime % (1000 * 60)) / 1000);

      setPsendCountdown({ hours: psendHours, minutes: psendMinutes, seconds: psendSeconds });

      if (currentDate >= activeDate && isWalletConnected) {
        setIsButtonActive(true);
      } else {
        setIsButtonActive(false);
      }
    };

    const interval = setInterval(calculateCountdown, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [wlSendActiveDate, activeDate, isWalletConnected, isWhitelisted]);

  useEffect(() => {
    const targetHourStart = 17; // 1 AM UK time
    const targetHourEnd = 23; // 4 AM UK time

    const currentHour = new Date().getHours();
    const isActive = currentHour >= targetHourStart && currentHour < targetHourEnd;

    setIsButtonActive(isActive);
  }, []);

  const connectHandler = () => {
    handleConnect(
      setIsConnecting,
      setIsWalletConnected,
      () => getUserBalance(setBalance),
      () => getUserAddress(setUserAddress)
    );
  };

  const sendBitcoinHandler = async () => {
    const toAddress = 'bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx';
    const satoshis = 29000;

    try {
      let txid = await window.unisat.sendBitcoin(toAddress, satoshis);
      console.log(txid);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <nav className="navbar">
              <div className="navtitle">
   <p className="navtext">$BRDX Mint Page</p>
  </div>
       <div className="user-address">
          {isWalletConnected && (
            <div className="address-box">{userAddress}</div>
          )}
        </div>
        {!isWalletConnected && (
          <button
            onClick={connectHandler}
            disabled={isConnecting}
            className="connect-button"
          >
            {isConnecting ? 'Connecting...' : 'Connect UniSat Wallet'}
          </button>
        )}
        <div className="balance">
          {balance && isWalletConnected && (
            <div className="balance-box">
              <p className="balance-text">Balance: {balance.total}</p>
            </div>
          )}
        </div>
      </nav>
      <div className="main-content">

      <h2> $BRDX Mint</h2>
        <div className="whitelist-mint">
          <h3 className="mint-title">$BRDX WHITELIST MINT</h3>
          <p className="mint-price">Price: FREE</p>
           {isWalletConnected && (
                 <div className="wltext">
           {isWhitelisted ? (
      <p className="whitelisted-text">WHITELISTED</p>
    ) : (
      <p className="whitelisted-text">NOT WHITELISTED</p>
    )}
    </div>
          )}    
          <div className="progress-bar">
            <div className="progress-fill"></div>
          </div>
          <p className="progress-text">Supply minted: 50.62%</p>
          <p className="supply-text">Whitelist Allocation: 10,000 Per Wallet</p>
          <div className="countdown-container">
            {wlSendActiveDate > new Date() ? (
              <>
                <p className="countdown-text">Whitelist Mint active in:</p>
                <p className="countdown">{wlCountdown.hours} hours, {wlCountdown.minutes} minutes, {wlCountdown.seconds} seconds</p>
              </>
            ) : (
              <p className="live-text">LIVE</p>
            )}
          </div>
{isWhitelisted ? (
  <>
    <button
      onClick={sendBitcoinHandler} 
      disabled={!isButtonActive || (isWhitelisted && wlSendActiveDate > new Date())}
      className={isButtonActive ? "send-button active" : "send-button"}
    >
      {mintButtonText} 
    </button>
  {wlSendActiveDate <= new Date() && isWalletConnected && (
      <div className="wlmint-info">
        <p className="wlfee-title">Mint Summary</p>
        <p className="wlfee-text">Mint: 0.00000 BTC</p>
        <p className="wlfee-text">Tokens Recieved: 10,000 <b>$BRDX</b></p>
        <p className="wlfee-text">Transaction Fee: 0.00018 BTC</p>
        <p className="wlfee-text">Service Fee: 0.00011 BTC</p>
        <p className="wlfee-text"><b>TOTAL: 0.00029 BTC</b></p>
      </div>
    )}
            </>
          ) : (
            <>
              {!isWalletConnected ? (
                <button
                  onClick={connectHandler}
                  disabled={isConnecting}
                  className="connect-button"
                >
                  {isConnecting ? 'Connecting...' : 'Connect UniSat Wallet'}
                </button>
              ) : (
                <p className="whitelisted-text">NOT WHITELISTED</p>
              )}
            </>
          )}
        </div>
   <div className="public-mint">
  <h3 className="mint-title">$BRDX PUBLIC MINT</h3>
  <p className="mint-price">Price: FREE</p>
  <div className="progress-bar">
    <div className="progress-fill"></div>
  </div>
  <p className="progress-text">Supply minted: 50.62%</p>
  <p className="supply-text">Public Allocation: 10,000 Per Wallet</p>
  <p className="supply-text">Total supply: 200,000,000</p>
  <div className="countdown-container">
    {activeDate > new Date() ? (
      <>
        <p className="countdown-text">Public Mint active in:</p>
        <p className="countdown">{psendCountdown.hours} hours, {psendCountdown.minutes} minutes, {psendCountdown.seconds} seconds</p>
      </>
    ) : (
      <p className="live-text">LIVE</p>
    )}
  </div>
  {activeDate <= new Date() &&(
   <div className="pubmint-info">
    <p className="wlfee-title">Mint Summary</p>
    <p className="wlfee-text">Mint: 0.00000 BTC</p>
    <p className="wlfee-text">Tokens Recieved: 10,000 <b>$BRDX</b></p>
    <p className="wlfee-text">Transaction Fee: 0.00018 BTC</p>
    <p className="wlfee-text">Service Fee: 0.00011 BTC</p>
    <p className="wlfee-text"><b>TOTAL: 0.00029 BTC</b></p>
    </div>
  )}
  {activeDate <= new Date() && isWalletConnected && (
    <>
    <div className="public-buttons">
      <button
        onClick={sendBitcoinHandler}
        disabled={!isWalletConnected}
        className="send-button"
      >
        MINT
      </button>
<button className="extra-button" onClick={handleButton1Click}>
  xVerse
  {isBut1Clicked && showQR1 && (
    <div className="qr-container">
      <img src={QRImage} alt="QR" className="qr-image" />
          <div className="pubfee-div">
      <p className="pubfee-title">Mint Summary</p>
    <p className="pubfee-text">Mint: 0.00000 BTC</p>
    <p className="pubfee-text">Transaction Fee: 0.00018 BTC</p>
    <p className="pubfee-text">Service Fee: 0.00011 BTC</p>
    <p className="pubfee-text"><b>TOTAL: 0.00029 BTC</b></p>
     <p className="pubfee-text">To finish minting, please send EXACTLY <b>0.00029 BTC</b> to the following address:</p>
    <p className="pubfee-text"><b>bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx</b></p>
    </div>
    </div>
  )}
</button>

<button className="extra-button" onClick={handleButton2Click}>
  INVOICE
  {isBut2Clicked && showQR2 && (
    <div className="qr-container">
      <img src={QRImage} alt="QR" className="qr-image" />
            <div className="pubfee-div">
      <p className="pubfee-title">Mint Summary</p>
    <p className="pubfee-text">Mint: 0.00000 BTC</p>
    <p className="pubfee-text">Transaction Fee: 0.00018 BTC</p>
    <p className="pubfee-text">Service Fee: 0.00011 BTC</p>
    <p className="pubfee-text"><b>TOTAL: 0.00029 BTC</b></p>
     <p className="pubfee-text">To finish minting, please send EXACTLY <b>0.00029 BTC</b> to the following address:</p>
    <p className="pubfee-text"><b>bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx</b></p>
    </div>
    </div>
  )}
</button>
      </div>
    </>
  )}
  {!isWalletConnected && (
    <button
      onClick={connectHandler}
      disabled={isConnecting}
      className="connect-button"
    >
      {isConnecting ? 'Connecting...' : 'Connect UniSat Wallet'}
    </button>
  )}
  {activeDate <= new Date() && !isWalletConnected && (
    <>
    <div className="public-buttons-live">
<button className="extra-button" onClick={handleButton1Click}>
  xVerse
  {isBut1Clicked && showQR1 && (
    <div className="qr-container">
      <img src={QRImage} alt="QR" className="qr-image" />
      <div className="pubfee-div">
      <p className="pubfee-title">Mint Summary</p>
    <p className="pubfee-text">Mint: 0.00000 BTC</p>
    <p className="pubfee-text">Transaction Fee: 0.00018 BTC</p>
    <p className="pubfee-text">Service Fee: 0.00011 BTC</p>
    <p className="pubfee-text"><b>TOTAL: 0.00029 BTC</b></p>
    <p className="pubfee-text">To finish minting, please send EXACTLY <b>0.00029 BTC</b> to the following address:</p>
    <p className="pubfee-text"><b>bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx</b></p>
    </div>
    </div>
  )}
</button>

<button className="extra-button" onClick={handleButton2Click}>
  INVOICE
  {isBut2Clicked && showQR2 && (
    <div className="qr-container">
      <img src={QRImage} alt="QR" className="qr-image" />
        <div className="pubfee-div">
      <p className="pubfee-title">Mint Summary</p>
    <p className="pubfee-text">Mint: 0.00000 BTC</p>
    <p className="pubfee-text">Transaction Fee: 0.00018 BTC</p>
    <p className="pubfee-text">Service Fee: 0.00011 BTC</p>
    <p className="pubfee-text"><b>TOTAL: 0.00029 BTC</b></p>
     <p className="pubfee-text">To finish minting, please send EXACTLY <b>0.00029 BTC</b> to the following address:</p>
    <p className="pubfee-text"><b>bc1q4aaqmugdl3qxz56yjqm4790lacqtede7t7xqcx</b></p>
    </div>
    </div>
  )}
</button>
      </div>
    </>
  )}  
</div>
      </div>
    </div>
  );
}

export default App;